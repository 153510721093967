<template>
    <v-select :label="label" :value="value" @input="changed" :items="currencies" required ></v-select>
</template>

<script>
export default {
  props: ['value', 'label'],
  methods: {
    changed(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    currencies() {
      if (!this.$global.env.currencies) {
        return [];
      }
      return this.$global.env.currencies.map((c) => ({ value: c.id, text: c.name }));
    },
  },
};
</script>
